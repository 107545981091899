import { useAccount, useNetwork } from 'wagmi'
import logo from '../../assets/images/logo.gif'
import logoname from '../../assets/images/logo.gif'
import '../Header/header.css'
import { ConnectButton, useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit'
import { shortAddress } from '../../hooks/useContract'
import { defaultNetwork } from '../../config'
const Header = () => {
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const { address } = useAccount()
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork

    return (
        <>
            <header>
                <div className="container">
                    <div className="row align-items-center mx-5 my-2">
                        <div className="col-6 col-md-2 text-start">
                            <div className="logo">
                                <img src={logo} alt="logo" width="140" height="140" className='d-none d-md-block' />
                                <img src={logoname} alt="logo" width="140" height="140" className='d-block d-md-none' />
                            </div>
                        </div>
                        <div className={`col-8 text-center d-none d-md-block position-relative`}>
                            <ul className='menu list-unstyled m-0 p-0'>

                            </ul>
                        </div>
                        <div className="logoDesktop">
              <ul>
                <li>D</li>
                <li>e</li>
                <li>f</li>
                <li>i</li>
                </ul>
                </div>
                <div className="logoDesktop2">
                <ul>
                <li>W</li>
                <li>o</li>
                <li>r</li>
                <li>l</li>
                <li>d</li>
              
              
              </ul>
            </div>
                        <div className="col-6 col-md-2 text-end">
                            <div className='rightboxmenu'>
                                <ul className='balancebox list-unstyled m-0'>
                                    <li>
                                        {openConnectModal && (
                                            <button className='buttonGroup btn1' onClick={openConnectModal} type="button">
                                                Connect
                                            </button>
                                        )}
                                        {openAccountModal && (
                                            <button className='buttonGroup btn1' onClick={openAccountModal} type="button">
                                                {shortAddress(address)}
                                            </button>
                                        )}

                                        {isCorrectNetwork ? "" :
                                            <>
                                                {openChainModal && (
                                                    <button onClick={openChainModal} type="button" className='wnbtn buttonGroup mt-3'>
                                                        Wrong network
                                                    </button>
                                                )}
                                            </>
                                        }
                                        {/* <ConnectButton showBalance={false} accountStatus={'address'} chainStatus={"icon"} /> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="before">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </header>
        </>
    )
}

export default Header