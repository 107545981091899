import React, { useState, useEffect } from "react";
import { AdminGraph, AllSwapsInRangeSheet } from "../hooks/adminGraph";
import "../assets/CSS/Admin.css"
import { shortAddress, toLocale } from "../hooks/useContract";
import { DWCAddress, transactionUrl } from "../config";
import { formatUnits } from "viem";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";

import { Pagination } from "@mui/material";
import BlackListTable from "./BlacklistTable";
import ReportAnalytics from "./ReportAnalytics";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";
import { AnalyticsData } from "../hooks/useContractData";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Tabs, Tab, MenuItem, Menu, Button } from "@mui/material";









dayjs.extend(utc);

const AdminReport = () => {
  const { address } = useAccount();
  const { adminAddress } = AnalyticsData();

  const [timeRange, setTimeRange] = useState({ startTime: 0, endTime: 0 });
  const [btnTimeRange, setBtnTimeRange] = useState(null);


  const validAdmin = adminAddress.some(
    (item) => item.toLowerCase() === address?.toLowerCase()
  );

  const [skip, setSkip] = useState(0);
  const { data } = AdminGraph(skip, timeRange);

  // Table and admin data
  const tableData = data?.swappeds;
  const adminData = data?.adminActions;

  // For pagination
  const [dis, setDis] = useState(false);

  // For search and tab value
  const [value, setValue] = useState(0);

  // Handle pagination length
  const len = tableData?.length;

  // For changing the page
  const nextButton = () => {
    setSkip(skip + 10);
  };

  const dateToEpochConverter = (dateString) => {


    const dateObject = new Date(dateString);
    const epochTime = dateObject.getTime() / 1000;
    return epochTime;

  };

  const handleSheetDownload = async()=>{
   await AllSwapsInRangeSheet(timeRange);
  }

  const handleBtnRange = (btnRangeStr) => {

    if (btnRangeStr === "week") {
      const currentTimeInEpoch = dateToEpochConverter(new Date());
      const weekBefore = currentTimeInEpoch - 604800;
      setTimeRange({ startTime: weekBefore, endTime: currentTimeInEpoch });
      setBtnTimeRange({
        startTime: dayjs().utc().subtract(7, "day"),
        endTime: dayjs().utc(),
      });
    } else {
      const currentTimeInEpoch = dateToEpochConverter(new Date());
      const monthBefore = currentTimeInEpoch - 2592000;
      setTimeRange({ startTime: monthBefore, endTime: currentTimeInEpoch });
      setBtnTimeRange({
        startTime: dayjs().utc().subtract(30, "day"),
        endTime: dayjs().utc(),
      });
    }
  };

  const handleClear = ()=>{
    setBtnTimeRange(null);
    setTimeRange({ startTime: 0, endTime: 0 });
  }

  const pagechange2 = () => {
    if (skip >= 10) {
      setSkip(skip - 10);
      setDis(false);
    }
  };

  // For changing tab or some value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (len < 10) {
      setDis(true);
    }
  }, [len]);

  //  Code for pagination
  const [pagination, setPagination] = useState(0);
  const length = adminData?.length;

  const [indexOfLast, setindexOfLast] = useState(10);
  const [indexOfFirst, setindexOfFirst] = useState(0);

  function pagechange(event, value) {
    const currentPage = Number(value);
    const dataPerPage = 10;

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo);
    setindexOfLast(indexOfLastTodo);
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(length / 10);
    setPagination(PageCount);
  };

  useEffect(() => {
    pageHandler();
  }, [length]);

  const [search, setSearch] = useState();
  const [filteredLocations, setFilteredLocations] = useState(tableData);

  useEffect(() => {
    if (search) {
      setFilteredLocations(
        tableData.filter((location) =>
          (location.user ?? "").toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredLocations(tableData);
    }
  }, [search, tableData]);

  const notify = () => toast.success("Copy Successfully");

  // if (!validAdmin) {
  //   return <section className="mainbox_ad adminform">
  //     <div className="os_container_admin mb-20 mt-20">
  //       <div className="boxmain_ad">
  //         <h2 >Not Admin</h2>
  //       </div>
  //     </div>
  //   </section>;
  // }

  useEffect(() => {
    console.log(timeRange)
  }, [timeRange])


  return (
    <>
      <ReportAnalytics />
      <BlackListTable />

      <section
        className="boxsmain adminReportTable"
        style={{ marginTop: 40, marginBottom: 40 }}
      >
        <div className="os_container">
          <div style={{ marginBottom: "15px" }}>
            <Box
              sx={{
                width: "100%",
                bgcolor: "#000",
                marginBottom: "50px",
                marginTop: "30px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                // centered
                className="hello"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile="mobile"
              >
                <Tab sx={{ color: "#fff" }} label="Swap History" />
                <Tab sx={{ color: "#fff" }} label="Admin action log" />
              </Tabs>
            </Box>
          </div>
          {value === 0 && (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="abcde">
          <CalendarMonthIcon />
                <DemoContainer components={["SingleInputDateRangeField"]}>
                  <DateRangePicker
                    value={
                      btnTimeRange
                        ? [btnTimeRange.startTime, btnTimeRange.endTime]
                        : [null, null]
                    }
                    slots={{ field: SingleInputDateRangeField }}
                    timezone="UTC"
                    name="allowedRange"
                    onChange={(dateRange) => {
                      if (dateRange) {
                        if (dateRange[0] && dateRange[1]) {
                          // console.log(dateRange);
                          setTimeRange({
                            startTime: dateToEpochConverter(dateRange[0]),
                            endTime: dateToEpochConverter(dateRange[1]),
                          });
                          setBtnTimeRange({
                            startTime: dateRange[0],
                            endTime: dateRange[1],
                          });
                        }
                      }
                    }}
                  />
                </DemoContainer>
                </div>
              </LocalizationProvider>
              <div
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                  flexWrap: "wrap",
                  columnGap: "20px",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => handleBtnRange("week")}
                  className="btnexcel"
                  variant="contained"
                  color="primary"
                >
                  Week
                </Button>
                <Button
                  onClick={() => handleBtnRange("month")}
                  className="btnexcel"
                  variant="contained"
                  color="primary"
                >
                  Month
                </Button>
                <Button
                  onClick={() =>handleClear()}
                  className="btnexcel"
                  variant="contained"
                  color="primary"
                >
                  Clear
                </Button>
              </div>
              <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
                <form className="form" onSubmit={(e) => e.preventDefault()}>
                  <label htmlFor="search">
                    <input
                      className="input"
                      type="text"
                      required=""
                      placeholder="Wallet Address"
                      id="search"
                      onChange={(e) => setSearch(e.target.value)}
                      autoComplete="off"
                    />
                    <div className="fancy-bg" />
                    <div className="search">
                      <svg
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                      >
                        <g>
                          <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                        </g>
                      </svg>
                    </div>
                    <button
                      className="close-btn"
                      type="reset"
                      onClick={() => setSearch()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </label>
                </form>
              </div>
              <br />
              <div className="table-responsive">
                <table className="table align-middle table-hover mb-0 dashboardTable">
                  <tbody />
                  <thead className="table-light">
                    <tr>
                      <th className="text-center">Sr No</th>
                      <th className="text-left">Wallet</th>
                      <th className="text-left">From</th>
                      <th className="text-left">To</th>
                      <th className="text-left">Amount Bought</th>
                      <th className="text-left">Amount Sold</th>
                      <th className="text-left">Transaction Hash</th>
                    </tr>
                  </thead>
                  <tbody />
                  {filteredLocations ? (
                    filteredLocations.map((row, i) => (
                      <>
                        <tr key={i}>
                          <td>{1 + skip + i}</td>
                          <CopyToClipboard text={row?.user}>
                            <td onClick={notify}>
                              {shortAddress(row.user)}
                              <CopyAllIcon
                                style={{
                                  width: "17px",
                                  marginLeft: "3px",
                                  backgroundColor: "transparent",
                                  color: "white",
                                  padding: "0",
                                }}
                              />
                            </td>
                          </CopyToClipboard>
                          <td>
                            {row.from?.toLowerCase() ===
                            DWCAddress.toLowerCase()
                              ? "DWC"
                              : "USDT"}{" "}
                          </td>
                          <td>
                            {row.to?.toLowerCase() === DWCAddress.toLowerCase()
                              ? "DWC"
                              : "USDT"}{" "}
                          </td>
                          <td>
                            {toLocale(
                              formatUnits(
                                row?.amountOut,
                                row.to?.toLowerCase() ===
                                  DWCAddress.toLowerCase()
                                  ? 18
                                  : 6
                              )
                            )}
                          </td>
                          <td>
                            {toLocale(
                              formatUnits(
                                row?.amountIn,
                                row.from?.toLowerCase() ===
                                  DWCAddress.toLowerCase()
                                  ? 18
                                  : 6
                              )
                            )}
                          </td>
                          <td>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "#fff",
                                background: "transparent",
                              }}
                              to={transactionUrl + row?.transactionHash}
                              target="blank"
                            >
                              {shortAddress(row?.transactionHash)}{" "}
                              <LaunchIcon
                                sx={{
                                  width: "16px",
                                  marginLeft: "3px",
                                  color: "#2c70b3",
                                }}
                              />
                            </Link>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </table>
              </div>
              <div className="prevbtn">
                <button onClick={pagechange2}> &#10094; Prev </button>
                {dis ? (
                  ""
                ) : (
                  <button onClick={nextButton} disabled={dis}>
                    Next &#10095;
                  </button>
                )}
              </div>
              <Button
                onClick={handleSheetDownload}
                className="btnexcel2"
                variant="contained"
                color="primary"
              >
                Export sheet
              </Button>
            </>
          )}
        </div>

        {value === 1 && (
          <>
            <div className="os_container">
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["SingleInputDateRangeField"]}>
                  <DateRangePicker
                    slots={{ field: SingleInputDateRangeField }}
                    name="allowedRange"
                  />
                </DemoContainer>
              </LocalizationProvider>
              <div style={{ marginBottom: "15px" }}>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "#000",
                    marginBottom: "50px",
                    marginTop: "30px",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    // centered
                    className="hello"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile="mobile"
                  >
                    <Tab sx={{ color: "#fff" }} label="Week" />
                    <Tab sx={{ color: "#fff" }} label="Month" />
                  </Tabs>
                </Box>
              </div> */}
              <div className="table-responsive">
                <table className="table align-middle table-hover mb-0 dashboardTable">
                  <tbody />
                  <thead className="table-light   ">
                    <tr>
                      <th className="text-center">Sr No</th>
                      <th className="text-left">Admin</th>
                      <th className="text-left">Action</th>
                      <th className="text-left">TransactionHash</th>
                    </tr>
                  </thead>
                  <tbody />
                  {adminData ? (
                    adminData.slice(indexOfFirst, indexOfLast).map((row, i) => (
                      <>
                        <tr key={i}>
                          <td>{i + indexOfFirst + 1}</td>
                          <td></td>
                          <td>{row.actions}</td>
                          <td>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "#fff",
                                background: "transparent",
                              }}
                              to={transactionUrl + row?.transactionHash}
                              target="blank"
                            >
                              {shortAddress(row.transactionHash)}
                              <LaunchIcon
                                sx={{
                                  width: "16px",
                                  marginLeft: "3px",
                                  color: "#2c70b3",
                                }}
                              />
                            </Link>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </table>
              </div>
            </div>
            {pagination <= 1 ? (
              ""
            ) : (
              <>
                <Pagination
                  className="myPagination"
                  onChange={pagechange}
                  count={pagination}
                  color="secondary"
                />
              </>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default AdminReport;
