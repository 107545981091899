import React, { useEffect, useState } from 'react';
import '../assets/CSS/style.css'
import { formatUnits } from 'viem';
import { DWCAddress, transactionUrl } from '../config';
import { useGraphData } from '../hooks/useGraph';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LaunchIcon from '@mui/icons-material/Launch';
import { shortAddress, toLocale } from '../hooks/useContract';
import { Link } from 'react-router-dom';
import { Pagination } from "@mui/material";


const getDisplayData = (tableData, sortField, sortDirection, showZebOnTop) => {
  let data = [...(tableData || [])]; // Start with the raw data

  // Apply sorting
  if (sortField) {
    data.sort((a, b) => {
      switch (sortField) {
        case 'amountBought':
          return amountBought(a, b, sortDirection);
        case 'amountSold':
          return amountSold(a, b, sortDirection);
        default:
          return 0;
      }
    });
    return data
  } else {

    // If you want to apply the showZebOnTop filter ONLY after sorting:
    if (showZebOnTop === true) {
      data = [...data.filter(row => row.from.toLowerCase() === DWCAddress.toLowerCase()),
      ...data.filter(row => row.from.toLowerCase() !== DWCAddress.toLowerCase())];
    } else if (showZebOnTop === false) {
      data = [...data.filter(row => row.from.toLowerCase() !== DWCAddress.toLowerCase()),
      ...data.filter(row => row.from.toLowerCase() === DWCAddress.toLowerCase())];
    }

    return data;
  };
}

const amountBought = (a, b, direction) => {
  const valueA = formatUnits(a.amountOut, a.to.toLowerCase() === DWCAddress.toLowerCase() ? 18 : 6);
  const valueB = formatUnits(b.amountOut, b.to.toLowerCase() === DWCAddress.toLowerCase() ? 18 : 6);
  return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
};

const amountSold = (a, b, direction) => {
  const valueA = formatUnits(a.amountIn, a.from.toLowerCase() === DWCAddress.toLowerCase() ? 18 : 6);
  const valueB = formatUnits(b.amountIn, b.from.toLowerCase() === DWCAddress.toLowerCase() ? 18 : 6);
  return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
};

const SwapHistory = () => {

  const { data } = useGraphData()

  const tableData = data?.swappeds


  //  Code for pagination 
  const [pagination, setPagination] = useState(0);
  const len = tableData?.length || 0

  const [indexOfLast, setindexOfLast] = useState(10)
  const [indexOfFirst, setindexOfFirst] = useState(0)
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' for ascending and 'desc' for descending
  const [sortField, setSortField] = useState(''); // column/field to sort by
  const [showZebOnTop, setShowZebOnTop] = useState();

  function pagechange(event, value) {
    const currentPage = Number(value)
    const dataPerPage = 10

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo)
    setindexOfLast(indexOfLastTodo)
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(len / 10)
    if (!isNaN(PageCount)) { // Ensure PageCount is a valid number before setting it
      setPagination(PageCount);
    }
  }

  useEffect(() => {
    pageHandler()
  }, [len])



  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };


  const handleFromClick = () => {
    setSortField()
    setShowZebOnTop(prev => !prev);
  };




  // Now use getDisplayData in your rendering:
  const displayData = getDisplayData(tableData, sortField, sortDirection, showZebOnTop);

  return (
    <>

      <div className="boxsmaint adminReportTable2 mb-5 ">
        <div className="os_container mt:0 md:mt-28">
          <div className="titleh2n text-center  anlytcs titlebox blinkers ">
            <h2 className=' text-4xl md:text-5xl mb-8 font-bold anltycs  '><span className='anlytcs'>Swap</span> History</h2>
          </div>
          <div className="table-responsive adminReportTable">
            <table className="table align-middle table-hover mb-0 dashboardTable">
              <thead className="table-light">
                <tr>
                  <th className="text-center">Sr No</th>
                  <th className="text-left" onClick={handleFromClick}>
                    From
                    {showZebOnTop ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </th>
                  <th className="text-left" >
                    To
                  </th>

                  <th className="text-left" onClick={() => handleSort('amountBought')}>Amount Bought
                    {sortField === 'amountBought' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </th>
                  <th className="text-left" onClick={() => handleSort('amountSold')}>Amount Sold
                    {sortField === 'amountSold' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </th>
                  <th className="text-left">Transaction</th>
                </tr>
              </thead>
              <tbody>
                {displayData ? displayData.slice(indexOfFirst, indexOfLast).map((row, i) => (
                  < >
                    <tr>
                      <td>{i + indexOfFirst + 1}</td>
                      <td>{((row.from).toLowerCase() === (DWCAddress).toLowerCase() ? "DWC" : "USDT")} </td>
                      <td>{((row.to).toLowerCase() === (DWCAddress).toLowerCase() ? "DWC" : "USDT")} </td>
                      <td>{toLocale(formatUnits(row.amountOut, (row.to).toLowerCase() === (DWCAddress).toLowerCase() ? 18 : 6))}</td>
                      <td>{toLocale(formatUnits(row.amountIn, (row.from).toLowerCase() === DWCAddress.toLowerCase() ? 18 : 6))}</td>
                      <td>
                        <Link style={{ textDecoration: "none", color: '#fff' }} to={transactionUrl + row?.transactionHash} target="blank">
                          {shortAddress(row.transactionHash)} <LaunchIcon sx={{ width: "16px", marginLeft: "3px", color: "#2c70b3" }} />
                        </Link>
                      </td>
                    </tr>
                  </>
                )) :
                  <>
                  </>
                }
              </tbody>
            </table>
            {displayData.length === 0 ?
              <h5 className="text-center mt-5 mb-5">No data found</h5> : ""}
          </div>
        </div>
      </div>
      {pagination <= 1 ?
        ''
        : <>
          <Pagination className='myPagination' onChange={pagechange} count={pagination} color="primary" />
        </>
      }
    </>
  );
};

export default SwapHistory;
