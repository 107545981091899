import React from 'react';

const FormField = ({ label, placeholder, onSubmit, onChange }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const value = e.target.elements.inputField.value;
        onSubmit(value);
    };

    return (
        <div className="os_row_ad items-center  boxpart_ad">
            <div className="leftbx_ad">
                <strong>{label}</strong>
            </div>
            <div className="inputbox_ad">
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="inputField"
                        autoComplete="off"
                        placeholder={placeholder}
                        onChange={onChange}
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default FormField;


