import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";

export const useGraphData = () => {
  const [data, setData] = useState([]);
  const [tradeVolume, setTradeVol] = useState([]);
  const { address } = useAccount();
  const fetchGraphData = useCallback(async () => {
    try {
      const now = Math.floor(Date.now() / 1000);
      const twentyFourHoursAgo = now - 24 * 60 * 60;

      if (address) {
        const options = {
          method: "POST",
          url: graphQlUrl,
          headers: {
            "content-type": "application/json",
          },
          data: {
            query: `{
                            swappeds(where:{
                                user:"${address}"
                              },orderBy: blockTimestamp,  orderDirection: desc) {
                                user
                                from
                                to
                                amountIn
                                amountOut
                                swapRate
                                transactionHash
                              }
                              
                                tradeVolumes (where:{
                                    blockTimestamp_gte: "${twentyFourHoursAgo}",
                                    blockTimestamp_lte: "${now}"
                                  }) {
                                  USDT
                                  blockTimestamp
                                }
                              
                            }`,
          },
        };
        const response = await axios.request(options);
        const res = response.data.data;
        if (res.swappeds.length > 0) {
          setData(res);
        } else {
          setData([]);
        }
        if (res.tradeVolumes.length > 0) {
          setTradeVol(res.tradeVolumes);
        } else {
          setTradeVol([]);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [address, setData]);

  useEffect(() => {
    const inter = setInterval(() => {
      fetchGraphData();
    }, 8000);

    return () => clearInterval(inter);
  }, [fetchGraphData, address]);

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData, address]);

  return useMemo(() => {
    return {
      data,
      tradeVolume,
    };
  }, [data, tradeVolume]);
};

export const InFlowOutFlowData = async () => {

  const firstSwapBlockNumber = await fetchFirstSwapBlockNumber();
    let AllSwaps = [];

    let flag = true;

    let blockNumber = firstSwapBlockNumber;

    while (flag) {

  const options = {
    method: "POST",
    url: graphQlUrl,
    headers: {
      "content-type": "application/json",
    },
    data: {
      query: `{
             swappeds(where:{
                                    blockNumber_gte: "${blockNumber}",
                                  },
                                orderBy: blockNumber,  orderDirection: asc) {
                                from
                                to
                                amountIn
                                amountOut
                                blockNumber
                              }
                            }`,
    },
  };

   const response = await axios.request(options);
   const swaps = response.data.data.swappeds;

   if (swaps.length === 100) {
     AllSwaps = AllSwaps.concat(swaps);
     let lastOne = swaps[swaps.length - 1].blockNumber;
     blockNumber = lastOne;
   } else {
      AllSwaps = AllSwaps.concat(swaps);
     flag = false;
   }
}; // end of while loop


let { dwcInflow, dwcOutflow, usdtInflow, usdtOutflow } =
  calculateTotalFlows(AllSwaps);
    return { dwcInflow, dwcOutflow, usdtInflow, usdtOutflow };
};

const fetchFirstSwapBlockNumber = async () => {
  const options = {
    method: "POST",
    url: graphQlUrl,
    headers: {
      "content-type": "application/json",
    },
    data: {
      query: `{
            swappeds(first:1 orderBy: blockNumber,  orderDirection: asc) {
                blockNumber
              }
            }`,
    },
  };

  const response = await axios.request(options);
  const firstSwapBlockNumber = response.data.data.swappeds[0].blockNumber;
  return parseInt(firstSwapBlockNumber);
};


 const TOKEN_DECIMALS = {
   "0xc2132d05d31c914a87c6611c10748aeb04b58e8f": 6, // USDT
   "0x96f0a7167ab7ba6e59ffb68707c9d1b049524b0f": 18, // DWC
 };

 const adjustAmountForDecimals = (amount, decimals) => {
   return parseFloat(amount) / Math.pow(10, decimals);
 };

 const calculateTotalFlows = (swaps) => {
   let dwcInflow = 0,
     dwcOutflow = 0,
     usdtInflow = 0,
     usdtOutflow = 0;

   swaps.forEach((swap) => {
     const fromToken = swap.from.toLowerCase();
     const toToken = swap.to.toLowerCase();

     if (toToken === "0x96f0a7167ab7ba6e59ffb68707c9d1b049524b0f") {
       // DWC inflow
       dwcInflow += adjustAmountForDecimals(
         swap.amountOut,
         TOKEN_DECIMALS[toToken]
       );
       if (fromToken === "0xc2132d05d31c914a87c6611c10748aeb04b58e8f") {
         // USDT outflow
         usdtOutflow += adjustAmountForDecimals(
           swap.amountIn,
           TOKEN_DECIMALS[fromToken]
         );
       }
     }

     if (fromToken === "0x96f0a7167ab7ba6e59ffb68707c9d1b049524b0f") {
       // DWC outflow
       dwcOutflow += adjustAmountForDecimals(
         swap.amountIn,
         TOKEN_DECIMALS[fromToken]
       );
       if (toToken === "0xc2132d05d31c914a87c6611c10748aeb04b58e8f") {
         // USDT inflow
         usdtInflow += adjustAmountForDecimals(
           swap.amountOut,
           TOKEN_DECIMALS[toToken]
         );
       }
     }
   });

   dwcInflow = dwcInflow.toFixed(2);
    dwcOutflow = dwcOutflow.toFixed(2);
    usdtInflow = usdtInflow.toFixed(2);
    usdtOutflow = usdtOutflow.toFixed(2);

   return { dwcInflow, dwcOutflow, usdtInflow, usdtOutflow };
 };