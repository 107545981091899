import React from 'react'
import Banner from '../components/Banner'
import Analytics from '../components/Analytics'
import History from '../components/History'
import Faqs from "../components/Faqs/Faqs"
// import Helpbtn from '../components/Helpbutton/Helpbutton/Helpbtn'


const Home = () => {
  return (
    <>
      <Banner />
      
      <Analytics />
      <History />
      <Faqs />
      {/* <Helpbtn /> */}
    </>
  )
}

export default Home