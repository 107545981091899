import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";
import createExcelFile from "./dataSheetHandle";

export const AdminGraph = (skip, timeRange) => {
  const [data, setData] = useState([]);
  const { address } = useAccount();

  if (timeRange) {
    timeRange.startTime = parseInt(timeRange.startTime);
    timeRange.endTime = parseInt(timeRange.endTime);
  } else {
    timeRange = {
      startTime: 0,
      endTime: 0,
    };
  }

  // console.log(` blockTimestamp_gte:${timeRange.startTime}
  //   blockTimestamp_lte:${timeRange.endTime}`);

  let timeRangeCond =
    timeRange.startTime !== 0
      ? ` where: {
      blockTimestamp_gte:${timeRange.startTime}
      blockTimestamp_lte:${timeRange.endTime}
    }`
      : "";

  let fetchQuery = `{
                            swappeds( 
                                first: 10
                                skip: ${skip}
                                orderBy: blockTimestamp
                                orderDirection: desc
                                ${timeRangeCond}
                                ){
                                user
                                from
                                to
                                amountIn
                                amountOut
                                swapRate
                                blockTimestamp
                                transactionHash
                              }
                              
                              adminActions(orderBy: blockTimestamp  orderDirection: desc){
                                admin
                                actions
                                transactionHash
                              }
                              
                            }`;

  const fetchGraphData = useCallback(async () => {
    try {
      if (address) {
        const options = {
          method: "POST",
          url: graphQlUrl,
          headers: {
            "content-type": "application/json",
          },
          data: {
            query: fetchQuery,
          },
        };

        const response = await axios.request(options);
        const res = response.data.data;
        // console.log("res", res.swappeds);
        setData(res);
      }
    } catch (err) {
      console.error(err);
    }
  }, [address, setData, skip]);

  useEffect(() => {
    const inter = setInterval(() => {
      fetchGraphData();
    }, 6000);

    return () => clearInterval(inter);
  }, [fetchGraphData, address]);

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData, address]);

  return useMemo(() => {
    return {
      data,
    };
  }, [data]);
};

export const AllSwapsInRangeSheet = async (timeRange) => {

  // console.log("timeRange in sheet : ", timeRange)

  if (!timeRange || timeRange?.startTime == 0)
    return alert("Choose a time range to export sheet");

  let AllSwaps = [];
  let flag = true;
  let blockTimestampInitiate = timeRange.startTime;

  while (flag) {
    const options = {
      method: "POST",
      url: graphQlUrl,
      headers: {
        "content-type": "application/json",
      },
      data: {
        query: `{
             swappeds(where: {
      blockTimestamp_gte:${blockTimestampInitiate}
      blockTimestamp_lte:${timeRange.endTime}
    }
           orderBy: blockTimestamp
          orderDirection: asc) {
           user
          from
          to
          amountIn
          amountOut
          blockTimestamp
          transactionHash
        }
      }`,
      },
    };

    const response = await axios.request(options);
    const swaps = response.data.data.swappeds;

    // console.log("loop swaps length", swaps.length);

    if (swaps.length === 100) {
      AllSwaps = AllSwaps.concat(swaps);
      let lastOne = swaps[swaps.length-1].blockTimestamp;
      blockTimestampInitiate = lastOne;
    } else {
      AllSwaps = AllSwaps.concat(swaps);
      flag = false;
    }
  } // end of while loop

  // console.log("AllSwaps sheet length", AllSwaps.length);

  await createExcelFile(AllSwaps);

};
