import React, { useState } from "react";
import '../assets/CSS/Admin.css'
import FormField from "./AdminPanelComponent";
import ReportAnalytics from "./ReportAnalytics";
import { useAccount } from "wagmi";
import { AdminFunction, AnalyticsData } from "../hooks/useContractData";
import toast from "react-hot-toast";
import { DWCAddress, USDTAddress, apiSecret } from "../config";
import { SwapRate } from "../hooks/useContractData";


const Adminpanel = () => {

  const [prices, setPrices] = useState({ sell: 0, buy: 0 });
  const { address } = useAccount()
  const handlePriceChange = (type, value) => {
    setPrices({ ...prices, [type]: value });
  };
  const { feeAddress, adminFees, adminAddress, perSwapLimit } = AnalyticsData()

  const { tokenLiquidity } = SwapRate();

  const validAdmin = adminAddress.some(item => item.toLowerCase() === address?.toLowerCase())

  async function isEthereumAddress(address) {
    const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return ethereumAddressRegex.test(address);
  }


  const addToBlacklist = async (address) => {
    try {

      if (validAdmin === false) {

        toast.error("Not a Valid Admin")
      } else {

        const checkPoint = await isEthereumAddress(address)
        if (checkPoint === true) {
          const inputData = { address: address.toLowerCase() };

          const response = await fetch('https://defi-node.vercel.app/add_12blacklist21', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer${apiSecret}`
            },
            body: JSON.stringify(inputData),
          });

          if (response) {

            const data = await response.json();


            if (data.success === true) {
              toast.success(data.msg)
            } else {
              toast.error(data.msg)
            }

          } else {


            console.error("Failed to get:", response);
          }
        }
        else {


          toast.error("Enter a valid Address")
        }
      }

    } catch (error) {


      console.error("Error posting data:", error);

      return false
    }
  };

  const removeFromBlacklist = async (address) => {
    try {

      if (validAdmin === false) {
        toast.error("Not a Valid Admin")
      } else {
        const checkPoint = await isEthereumAddress(address)
        if (checkPoint === true) {
          const inputData = { address: address.toLowerCase() };
          const response = await fetch('https://defi-node.vercel.app/remove_123blacklist32', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer${apiSecret}`
            },
            body: JSON.stringify(inputData),
          });

          if (response) {
            const data = await response.json();


            if (data.acknowledged === true & data.deletedCount > 0) {
              toast.success("Whitelisted Successfully")
            }
            else {
              toast.error("Address is not in the Blacklist")
            }

          } else {
            console.error("Failed to save user:", response);
          }
        } else {


          toast.error("Enter a valid Address")
        }
      }
    } catch (error) {


      console.error("Error posting data:", error);
    }
  };

  const updateTradeVol = async (amount) => {

    try {
      if (validAdmin === false) {


        toast.error("Not a Valid Admin")
      } else {
        const inputData = { amount: Number(amount) };

        const response = await fetch('https://defi-node.vercel.app/update_vol', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer${apiSecret}`
          },
          body: JSON.stringify(inputData),
        });

        if (response) {
          const data = await response.json();


          if (data.success === true) {
            toast.success(data.msg)
          } else {
            toast.error(data.msg)
          }

        } else {
          console.error("Failed to get:", response);
        }
      }

    } catch (error) {

      console.error("Error posting data:", error);

      return false
    }
  };

  // get token liquidity...

 const updateTokenLiq = async (newVal,tokenStr) => {

  newVal = Number(newVal);

   try {

         if (validAdmin === false) {

        toast.error("Not a Valid Admin")
      } else {
     
     const inputData = { tokenLiquidity: [] };

    if(tokenStr === "dwc" && tokenLiquidity.length > 0){

      if(Number(newVal == tokenLiquidity[0])) return toast.error("Already Updated")
      inputData.tokenLiquidity = [newVal,tokenLiquidity[1]]
    }else{

      if(Number(newVal == tokenLiquidity[1])) return toast.error("Already Updated")
      inputData.tokenLiquidity = [tokenLiquidity[0],newVal]
    }

  const response = await fetch("https://defi-node.vercel.app/update_tokenLiq", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer${apiSecret}`,
    },
    body: JSON.stringify(inputData),
  }).catch((error) => console.error("Error:", error));

  if (response) {
    const data = await response.json();
    console.log("UPDATED....", data); // log the actual response data

    if (data.success === true) {
      toast.success(data.msg);
    } else {
      toast.error(data.msg);
    }

  } else {
    console.error("Failed to get:", response);
  }
      }
   } catch (error) {
     console.error("Error posting data:", error);
     return false;
   }

 };



  const { setUsdtSwapRate, AddLiquidity, EmergencyWithdraw, EmergencyWithdrawAll, ChangeAdminFee, ChangeFeeAddress, ChangePerSwapMaxLimit, setZebSwapRate } = AdminFunction()

  // if (!validAdmin) {
  //   return <section className="mainbox_ad adminform">
  //     <div className="os_container_admin mb-20 mt-20">
  //       <div className="boxmain_ad">
  //         <h2 >Not Admin</h2>
  //       </div>
  //     </div>
  //   </section>;
  // }

  return (
    <>
      <section className="mainbox_ad adminform">
        <div className="os_container_admin mb-20 mt-20">
          <div className="boxmain_ad">
            <div className="boxinner_ad">
              <FormField
                label="Blacklist Address :"
                placeholder="Enter Blacklist Address"
                onSubmit={addToBlacklist}
              />
              <FormField
                label="Whitelist Address :"
                placeholder="Enter WhiteList Address"
                onSubmit={removeFromBlacklist}
              />
              <FormField
                label="24h Trade Volume :"
                placeholder="Enter Amount"
                onSubmit={updateTradeVol}
              />

              <FormField
                label="DWC liquidity :"
                placeholder="Enter Amount"
                onSubmit={(inputValue) => updateTokenLiq(inputValue, "dwc")}
              />
              <FormField
                label="USDT liquidity :"
                placeholder="Enter Amount"
                onSubmit={(inputValue) => updateTokenLiq(inputValue, "usdt")}
              />

              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>AddLiquidity</strong>
                </div>
                <div className="inputbox_ad">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      AddLiquidity(e.target.token.value, e.target.amount.value);
                    }}
                  >
                    <input
                      classname="inputswap"
                      type="number"
                      name="amount"
                      autoComplete="off"
                      placeholder="Enter Amount"
                    />

                    <select className="inputswapbtn" name="token" id="">
                      <option value={DWCAddress}>DWC</option>
                      <option value={USDTAddress}>USDT</option>
                    </select>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Emergency Withdraw</strong>
                </div>
                <div className="inputbox_ad">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      EmergencyWithdraw(
                        e.target.token.value,
                        e.target.amount.value
                      );
                    }}
                  >
                    <input
                      classname="inputswap"
                      type="number"
                      name="amount"
                      autoComplete="off"
                      placeholder="Enter Amount"
                    />

                    <select className="inputswapbtn" name="token" id="">
                      <option value={DWCAddress}>DWC</option>
                      <option value={USDTAddress}>USDT</option>
                    </select>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              <div className="os_row_ad items-center  boxpart_ad">
                <div className="leftbx_ad">
                  <strong>Emergency (ALL)</strong>
                </div>
                <div className="inputbox_ad">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      EmergencyWithdrawAll(e.target.token.value);
                    }}
                  >
                    <select className="Emergencydrop" name="token" id="">
                      <option value={DWCAddress}>DWC</option>
                      <option value={USDTAddress}>USDT</option>
                    </select>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              <FormField
                label="Admin Fee (%): MIN (0.001%) :"
                placeholder={adminFees}
                onSubmit={ChangeAdminFee}
              />
              <FormField
                label="Change Fee Address :"
                placeholder={feeAddress}
                onSubmit={ChangeFeeAddress}
              />
              <FormField
                label="Max Swap Limit :"
                placeholder={perSwapLimit}
                onSubmit={ChangePerSwapMaxLimit}
              />
              <FormField
                label={`Sell Price ( 1 DWC = ${prices.sell} USDT ) :`}
                placeholder="Enter Amount"
                onSubmit={(val) => setZebSwapRate(val)}
                onChange={(e) => handlePriceChange("sell", e.target.value)}
              />
              <FormField
                label={`BUY Price ( 1 DWC = ${prices.buy} USDT ) :`}
                placeholder="Enter Amount"
                onSubmit={(val) => setUsdtSwapRate(val)}
                onChange={(e) => handlePriceChange("buy", e.target.value)}
              />
            </div>
          </div>
        </div>
        <ReportAnalytics />
      </section>
    </>
  );
};

export default Adminpanel;
