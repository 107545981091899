import { providers } from "ethers"
import Web3 from "web3"

 // export const swapContract = "0xBc35bb3E293Fd944bffF365ACA12708ecF3Fc918" // defi testnet
  export const swapContract = "0x065D4eA89D943397b9363E24724dAe18Fc79b986"; // defi mainnet

// TEST COIN  ADDRESS
// export const DWCAddress = "0x8e41D29D39f285b6b7e08D47fc4d9205Dd96a1d4" 
// export const USDTAddress = "0x9441C14C79c37f60F84DFc7C238E15C88489ACA0" 

// Main COIN  ADDRESS
 export const DWCAddress = "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F"; 
 export const USDTAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"; 

export const apiSecret = process.env.REACT_APP_API_SECRET;

export const graphQlUrl =
  "https://api.studio.thegraph.com/query/60406/defiworld-swap-thegraph-m/version/latest"; 

export const transactionUrl = "https://polygonscan.com/tx/" // mainnet


export const chainRPC =
  "https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7//";  // mainnet link
export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 137
export const polygonchainRPC = "https://polygon-rpc.com"


export const tokenDetails = [{ address: DWCAddress, label: "DWC", value:"DWC" },
{ address: USDTAddress, label: "USDT",value:"USDT" }
]









