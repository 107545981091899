import bannerimage2 from '../assets/images/v2 (1).gif'
import Swap from "./Swap";
import '../assets/CSS/style.css'


function Banner() {

  // asas

  return (
    <>
      <section className="banner-area md:mt-0  mt-6 " id="Home">
        <div className="container mx-auto ">
          <div className=" lg:flex lg:flex-row lg:items-center mb-10 mt-[60px]">
            <div className="lg:w-1/2 mt-2">
              <div className="info-group os_container2" data-aos="fade-left">
                
                <h1 className=" text-3xl fnt  space-title   sm:text-4xl md:text-[45px] mb-4 md:mb-2 md:px-0 px-3 sm:mt-2 mt-3  space-title text-center md:text-left ">
                Empowering Digital Ownership with DeFi World Swapping
                </h1>
                <p className=" text-base mt-2   md:mt-4 md:px-0 px-3  text-justify ">
                  Trade Wisely and Securely in a Decentralized World
                  Uncover the Ultimate Decentralized Solution for Exchanging Your DeFi World Tokens with USDT. 

                </p>
                <p className=" text-base mt-1   md:mt-4 md:px-0 px-3  text-justify ">
                 DeFi World Swap offers you a platform with low fees, high transaction limits,
                  and an effortless user experience, all within a decentralized framework.
                </p>
                
              </div>
            </div>

            <div className="lg:w-1/2 swapbox mt-5 sm:mt-0">
            <div className="banner-img-group mt-0 md:mt-[40px]">
                  <img
                    className="banner-img"
                    src={bannerimage2}
                    height={480}
                    width={480}
                    alt="banner-logo"
                  />
                </div>
                {/* <Swap /> */}
            </div>

          </div>
          <Swap />
        </div>
      </section>


    </>
  );
};

export default Banner;
