import React, { useState } from "react";
import { saveAs } from "file-saver";
// import * as XLSX from "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js";

const TOKEN_SYMBOLS = {
  "0xc2132d05d31c914a87c6611c10748aeb04b58e8f": "USDT",
  "0x96f0a7167ab7ba6e59ffb68707c9d1b049524b0f": "DWC",
};

const TOKEN_DECIMALS = {
  "0xc2132d05d31c914a87c6611c10748aeb04b58e8f": 6, // USDT
  "0x96f0a7167ab7ba6e59ffb68707c9d1b049524b0f": 18, // DWC
};

const adjustAmountForDecimals = (amount, decimals) => {
  return parseFloat(amount) / Math.pow(10, decimals);
};

const formatSwap = (swap) => {
  const {
    user,
    from,
    to,
    amountIn,
    amountOut,
    blockTimestamp,
    transactionHash,
  } = swap;
  const fromToken = TOKEN_SYMBOLS[from.toLowerCase()];
  const toToken = TOKEN_SYMBOLS[to.toLowerCase()];

  const fromAmount = adjustAmountForDecimals(
    amountIn,
    TOKEN_DECIMALS[from.toLowerCase()]
  );
  const toAmount = adjustAmountForDecimals(
    amountOut,
    TOKEN_DECIMALS[to.toLowerCase()]
  );

  const date = new Date(blockTimestamp * 1000).toLocaleString();
  const txLink = `https://polygonscan.com/tx/${transactionHash}`;

  return {
    User: user,
    From: fromToken,
    To: toToken,
    "Amount Bought": toAmount,
    "Amount Sold": fromAmount,
    Date: date,
    "Transaction Link": txLink,
  };
};


let XLSX;

const loadSheetJS = async () => {
  const script = document.createElement("script");
  script.src =
    "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js";
  script.async = false;
  document.body.appendChild(script);

  return new Promise((resolve) => {
    script.onload = () => {
      XLSX = window.XLSX;
      resolve();
    };
  });
};

const createExcelFile = async (swaps) => {
  await loadSheetJS();

  swaps = swaps.reverse();  
  const formattedSwaps = swaps.map(formatSwap);
  const firstTxTimestamp = swaps[swaps.length-1].blockTimestamp;
  const lastTxTimestamp = swaps[0].blockTimestamp;
const formatFirstTxDate = new Date(firstTxTimestamp * 1000).toLocaleDateString();
const formatLastTxDate = new Date(lastTxTimestamp * 1000).toLocaleDateString();


//   const header = [
//     "User",
//     "From",
//     "To",
//     "Amount Bought",
//     "Amount Sold",
//     "Date",
//     "Transaction Link",
//   ];

//   const csvData = [header, ...formattedSwaps]
//     .map((row) => row.map((value) => `"${value}"`).join(","))
//     .join("\n");

//   const excelFile = new Blob([csvData], { type: "text/csv;charset=utf-8" });
//   const date = new Date().toLocaleDateString().split("/").join("-");
//   saveAs(excelFile, `swaps-${formatFirstTxDate}--${formatLastTxDate}.csv`);


 const worksheet = XLSX.utils.json_to_sheet(formattedSwaps, {
   header: [
     "User",
     "From",
     "To",
     "Amount Bought",
     "Amount Sold",
     "Date",
     "Transaction Link",
   ],
 });
 const workbook = XLSX.utils.book_new();
 XLSX.utils.book_append_sheet(workbook, worksheet, "Swaps");

 const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
 const fileName = `swaps-${formatFirstTxDate}--${formatLastTxDate}.xlsx`;

 const blob = new Blob([excelBuffer], {
   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
 });
 saveAs(blob, fileName);

  console.log("Excel file created successfully!");
};

export default createExcelFile;
