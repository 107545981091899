import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../src/pages/Layout'
import Home from '../src/pages/Home'
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { polygon, polygonMumbai } from 'wagmi/chains';
import { publicProvider } from "wagmi/providers/public"
import { RainbowKitProvider, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';
import Adminpanel from './components/Adminpanel';
import AdminReport from './components/Adminreport';


const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon],
  [publicProvider(), publicProvider()]
);
const { connectors } = getDefaultWallets({
  appName: 'Defi Swap',
  projectId: '74ff0c8063613ea7db447832e6049d4d',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data) to showcase the loader
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Set a timeout of 2000 milliseconds (adjust as needed)
  }, []);

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider coolMode chains={chains} theme={darkTheme()} showRecentTransactions={true}>
        <BrowserRouter>
          {loading ? (
            // Display the loader while loading is true
            <div className="loader-container">
      <span class="loader">
      </span>
          </div>
          ) : (
            // Render the Routes when loading is false
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='admin-panel' element={<Adminpanel />} />
                <Route path='admin-report' element={<AdminReport />} />
              </Route>
            </Routes>
          )}
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
