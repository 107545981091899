import React,{useState,useEffect} from 'react'
import { SwapRate } from '../hooks/useContractData'
import { toLocale } from '../hooks/useContract'
import { useGraphData, InFlowOutFlowData } from "../hooks/useGraph";
import { formatUnits } from 'viem';

export default function ReportAnalytics() {
  const { tradeVolume } = useGraphData()

  const [TotalFlow, setTotalFlow] = useState({ dwcInflow: 0, dwcOutflow: 0, usdtInflow: 0, usdtOutflow: 0 })

  const calculateTotal = () => {

    const stakedAmounts = tradeVolume.map(item => Number(formatUnits(item.USDT, 6)));

    const totalStakedAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);

    return totalStakedAmount;

  };

  const swapVolume = calculateTotal()
  const { sellPrice, buyPrice, zebBalance, usdtBalance, tradeVol } = SwapRate()

  useEffect(() => {

    InFlowOutFlowData().then((res)=>{
     if(res) setTotalFlow(res)
    })

  },[])

  return (
    <>
      <section
        className="boxsmain"
        style={{ marginTop: 100, marginBottom: 40 }}
      >
        <div className="os_container">
          <div className="titleh2">
            <h2>Analytics </h2>
          </div>
          <div className="os_row">
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(zebBalance)}</h2>
                <p>DWC Liquidity</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(usdtBalance)}</h2>
                <p>USDT Liquidity</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <p>
                  Buy Price
                  <br />1 DWC ~ {toLocale(1 / buyPrice)} USDT
                </p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <p>
                  Sell Price <br />1 DWC ~ {toLocale(sellPrice)} USDT
                </p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>${tradeVol + swapVolume}</h2>
                <p>
                  <p>24h Trade Volume</p>
                </p>
              </div>
            </div>

            <div className="col4">
              <div className="cardbox">
                <h2>{TotalFlow.dwcInflow} DWC</h2>
                <h2>{TotalFlow.usdtInflow} USDT</h2>
                <p>
                  <p>Inflow</p>
                </p>
              </div>
            </div>

            <div className="col4">
              <div className="cardbox">
                <h2>{TotalFlow.dwcOutflow} DWC</h2>
                <h2>{TotalFlow.usdtOutflow} USDT</h2>
                <p>
                  <p>Outflow</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}