import React,{useState,useEffect} from 'react';
import swappingDinamic from '../assets/images/sell-price.png';
import pool from '../assets/images/buy-price (1).png';
import amm from '../assets/images/24hr.png';
import liquid from "../assets/images/Liquidity Pool.png"
import graph from '../assets/images/bg_tvl1.1e2187ca.svg';
import graph2 from '../assets/images/graph2.svg';
import graph3 from '../assets/images/graph3.svg'
import '../assets/CSS/style.css'
import { SwapRate } from '../hooks/useContractData';
import { toLocale } from '../hooks/useContract';
import { useGraphData } from '../hooks/useGraph';
import { formatUnits } from 'viem';
import { DWCAddress, USDTAddress, swapContract } from "../config/index.js";
import ERC20ABI from "../abis/erc20.json";
import { ethers } from 'ethers';




function Analytics() {
    const { tradeVolume } = useGraphData()

    const calculateTotal = () => {
        const stakedAmounts = tradeVolume.map(item => Number(formatUnits(item.USDT, 6)));
        const totalStakedAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);
        return totalStakedAmount;
    };
    
    const swapVolume = calculateTotal()
    const { sellPrice, buyPrice, tradeVol, tokenLiquidity } = SwapRate();

    const [tokenBal, setTokenBal] = useState([0,0])

    const getLiquidity = async()=>{

       const provider = new ethers.providers.JsonRpcProvider(
         "https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"
       );


        const DWCContract = new ethers.Contract(DWCAddress, ERC20ABI, provider);
        const USDTContract = new ethers.Contract(USDTAddress, ERC20ABI, provider);
        const dwcBal = await DWCContract.balanceOf(swapContract);
        const usdtBal = await USDTContract.balanceOf(swapContract);
        
        const dwcFinalBal = ethers.utils.formatUnits(dwcBal, 18);
        const usdtFinalBal = ethers.utils.formatUnits(usdtBal, 6);
        return [parseFloat(dwcFinalBal).toFixed(2),parseFloat(usdtFinalBal).toFixed(2)]
    }

    useEffect(() => {
        if(!window) return;
        getLiquidity().then((res)=>{
            setTokenBal(res)
        })
    }, [])


    useEffect(()=>{
if (tokenLiquidity.length > 0) console.log();
    },[tokenLiquidity])


    return (
      <section className="boxsmain2 adminReportTable ">
        <div className="container adminReportTable2  ctr mt-15">
          <div className="titlebox text-center mt-52 ">
            <h2 className=" text-4xl md:text-5xl  font-semibold anltycs  ">
              <span className="anlytcs">Analytics</span>
            </h2>
          </div>
        </div>
        <div className="os_container">
          {/* <div className=" text-center text-4xl md:text-5xl mb-5 font-semibold titlebox  blinkers ">
                    <h2 className=' anltycs'>Analytics</h2></div> */}
          <div className="os_row2 ">
            <div className="col4 ">
              <div className="cardbox2 ">
                <div className="iconbox">
                  <img src={swappingDinamic} className=" " alt="" />
                </div>
                <h2>{sellPrice}</h2>
                <p>
                  Sell Price
                  <br />1 DWC ~ {toLocale(sellPrice)} USDT
                </p>
                <img
                  className="vtn-card-img w-[100%]"
                  src={graph}
                  alt="icon"
                ></img>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox2 ">
                <div className="iconbox">
                  <img src={pool} alt="" />
                </div>
                <h2>{toLocale(1 / buyPrice)}</h2>
                <p>
                  Buy Price
                  <br />1 DWC ~ {toLocale(1 / buyPrice)} USDT
                </p>
                <img
                  className="vtn-card-img w-[100%]"
                  src={graph2}
                  alt="icon"
                ></img>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox2 ">
                <div className="iconbox">
                  <img src={amm} alt="" />
                </div>
                <h2>${tradeVol + swapVolume}</h2>
                <p>24h Trade Volume</p>
                <img
                  className="vtn-card-img w-[100%]"
                  src={graph3}
                  alt="icon"
                ></img>
              </div>
            </div>
          </div>
          <div className="os_row3 ">
            <div className="col4 mg">
              <div className="cardbox2 ">
                <div className="iconbox">
                  <img src={liquid} alt="" />
                </div>
                {/* {console.log("tokenBal: ", tokenBal[0])}
                {console.log("tokenLiquidity: ", tokenLiquidity[0])} */}
                <h2>{Number(tokenBal[0]) + tokenLiquidity[0]} DWC</h2>
                <p>DWC Liquidity Pool</p>
                <img
                  className="vtn-card-img w-[100%]"
                  src={graph3}
                  alt="icon"
                ></img>
              </div>
            </div>

            <div className="col4 mg">
              <div className="cardbox2 ">
                <div className="iconbox">
                  <img src={liquid} alt="" />
                </div>
                <h2>{Number(tokenBal[1]) + tokenLiquidity[1]} USDT</h2>
                <p>USDT Liquidity Pool</p>
                <img
                  className="vtn-card-img w-[100%]"
                  src={graph3}
                  alt="icon"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Analytics;
