import React from 'react'
import "../Faqs/Faqs.css"
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "01 : What is the minimum limit for a swap transaction?",
            content: `
            The minimum limit for conducting a swap transaction in DEFI World is $10. This applies to all users and ensures manageable transaction sizes for efficient processing.`,
        },
        {
            title: "02 :  What is the maximum limit for a swap transaction?",
            content: `For selling transactions, the maximum limit is set at $10,000. However, there is no maximum limit imposed on buying transactions. This flexibility allows users to make larger purchases while maintaining a cap on sales for security and regulatory compliance.`,
        },
        {
            title: "03 :   What are the admin fees for a swap transaction?",
            content: `DeFi World charges a nominal admin fee of 1% for each swap transaction. This fee is utilized for the maintenance and development of the platform, ensuring a seamless and secure trading experience for all users.`,
        },
        {
            title: "04 :  What swapping pairs are available on DeFi World?",
            content: `Currently, DeFi World supports the swapping pair of DWC to USDT. This pairing allows users to trade between DWC and a stablecoin, offering a convenient way to manage assets within the platform.
            .
            `,
        },
        {
            title: "05 :What is the price of DWC in DeFi World?",
            content: `The price of DWC (DeFi World Coin) is not fixed and fluctuates based on market conditions. Users are advised to check the current market price on the platform before executing any transactions to ensure they are informed of the latest value.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#50dbff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#50dbff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true   
};
const Faqs = () => {
    return (
        <section className="faqs " id='faqsSrl '>
            <div className=" adminReportTable2  ctr mt-11">
                <div className='titlebox text-center mt-52 '>
                    <h6 className='   mb-1 md:mb-0 faQ mt-0 md:mt-36'>FAQ's</h6>
                    <h2 className='h1 text-4xl md:text-5xl mb-8 anltycs font-bold'>Quick Questions</h2>
                   
                </div>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs